import { Config } from "config/config.type"
/** @firescoutMockVar Config.config */
const config:Config = {
  "env": "prod",
  "location": "lusini_fr-fr",
  "shopName": "lusini",
  "locale": "fr-fr",
  "rootCategoryId": "EMG",
  "orderMinimumB2C": 20,
  "availableCreditCards": [
    "MASTERCARD",
    "VISA"
  ],
  "links": {
    "contact": "/aide-et-services/contactez-nous/"
  },
  "i18n": {
    "currency_ISO": "EUR",
    "locale": "fr-FR",
    "country": "FR"
  },
  "slugifyLocale": "fr",
  "hrefLang": [
    {
      "configFolder": "lusini",
      "locale": "fr-fr",
      "hrefLang": "fr"
    }
  ],
  "baseUrl": "https://www.lusini.com",
  "modules": {
    "onetrust": {
      "dataDomainScript": "bda0c016-94be-4fd9-943a-b9c09791a327"
    },
    "buzdev": {
      "errorUrl": "https://buz.lusini.com/webhook/com.lusini.commerce-platform/error/1-0-1"
    },
    "shopware": {
      "salutations": [
        "mr",
        "ms",
        "not_specified"
      ],
      "initialSaluationId": "6d0b33d96b5b400cbabc648c0a4c3f60",
      "accessKey": {
        "b2b": "SWSCXSC0JF4XO4GMCF8ZL8OU3X",
        "b2c": "SWSC91HUXRZSQJ2M9XV396C4D9"
      },
      "storefrontUrl": {
        "b2b": "https://www.lusini.com/fr-fr/b2b",
        "b2c": "https://www.lusini.com/fr-fr/b2c"
      },
      "apiUrl": "https://shop-api.lusini.com/store-api"
    },
    "cloudinary": {
      "endpoint": "https://res.cloudinary.com/lusini/",
      "transformations": {
        "freisteller_l": "w_1500,h_1500,q_80,c_pad,f_auto",
        "freisteller_l_2": "w_1500,h_1500,q_80,c_pad,f_auto",
        "milieu_l": "w_1500,h_1500,g_auto,q_80,c_fill,f_auto",
        "milieu_l_2": "w_1500,h_1500,g_auto,q_80,c_fill,f_auto",
        "freisteller_m": "w_500,h_500,q_70,c_pad,f_auto",
        "freisteller_m_2": "w_500,h_500,q_70,c_pad,f_auto",
        "milieu_m": "ws_500,h_500,g_auto,q_70,c_fill,f_auto",
        "milieu_m_2": "ws_500,h_500,g_auto,q_70,c_fill,f_auto",
        "freisteller_s": "w_300,h_300,q_70,c_pad,f_auto",
        "freisteller_s_2": "w_600,h_600,q_70,c_pad,f_auto",
        "milieu_s": "w_300,h_300,g_auto,q_70,c_fill,f_auto",
        "milieu_s_2": "w_600,h_600,g_auto,q_70,c_fill,f_auto",
        "freisteller_xs": "w_50,h_50,q_50,c_pad,fl_lossy,f_auto",
        "freisteller_xs_2": "w_100,h_100,q_50,c_pad,fl_lossy,f_auto",
        "milieu_xs": "w_50,h_50,g_auto,q_50,c_fill,fl_lossy,f_auto",
        "milieu_xs_2": "w_100,h_100,g_auto,q_50,c_fill,fl_lossy,f_auto"
      },
      "fallback_product_picture": "https://res.cloudinary.com/lusini/image/upload/v1643182695/application/products/lusini-online-shop-platzhalter-produktbild-in-kuerze-fr.svg",
      "newsletter_intentlayer_picture": "https://res.cloudinary.com/lusini/image/upload/v1719564168/application/NewsletterPopupAB/fstm9fb1gvvenbnosa8t.svg"
    },
    "algolia": {
      "apiKey": "bf4bf9193d5f34cd369647376521dbc8",
      "applicationId": "WAVYBEW6OQ"
    },
    "contentful": {
      "space": "aza65graowyr",
      "environment": "master",
      "accessToken": "OExq-bY8Ujs7M06rQEL1drC2kfafk-qmEzA8DZO0CCE",
      "previewToken": "QP7JxRhc0mYWGrX3I8vj9WdBO_eF7n9r0xnmcX11HAw"
    },
    "dyHybrid": {
      "apiKey": "e3241f26e68894b7351374710059355e2181541cd5ef10353a15507d5d1e859b",
      "campaignID": "9879543"
    },
    "trustShop": {
      "placeholderImg": "https://res.cloudinary.com/lusini/image/upload/c_scale,w_100/v1670412947/application/icons/trusted-shops-guarantee.svg",
      "id": "X0895289DB385CB1EF4B8D91B741E7D90",
      "url": "https://widgets.trustedshops.com/js/",
      "urlPostfix": ".js"
    },
    "zenloop": {
      "id": "TVdJeU9XUm1Oall0WXpSaVl5MDBOemt4TFdJMk4yTXROelZqTVdSak9UaG1NalEw"
    },
    "hotjar": {
      "id": 3549008,
      "version": 6
    },
    "taboola": {
      "id": "1648888"
    }
  },
  "index": {
    "products": "prod_lusini_fr_FR_products",
    "productsPriceAsc": "prod_lusini_fr_FR_products_priceAsc",
    "categories": "prod_lusini_fr_FR_categories",
    "series": "prod_lusini_fr_FR_series",
    "productsSuggestion": "prod_lusini_fr_FR_products_query_suggestions",
    "productsLatest": "prod_lusini_fr_FR_products_noveltiesAsc",
    "productReviews": "prod_lusini_product_reviews"
  },
  "deliveryDate": {
    "shippingTooLong": 200
  },
  "colors": {
    "Blanc": "white",
    "Noir": "black",
    "Gris": "grey",
    "Argenté": "silver",
    "Brun": "brown",
    "Bleu": "blue",
    "Multicolore": "multicolor",
    "Rouge": "red",
    "Beige": "beige",
    "Vert": "green",
    "Transparent": "transparent",
    "Jaune": "yellow",
    "Doré": "gold",
    "Orange": "orange",
    "Rose/Lilas": "lilac"
  },
  "features": {
    "creditCheck": false,
    "devMode": false,
    "trustShop": true,
    "taboola": true,
    "zenloop": true,
    "b2c": true,
    "acceptanceOfTermsRequiredForOrderingB2C": true,
    "acceptanceOfTermsRequiredForOrderingB2B": true,
    "userLikeWidgetUrl": "",
    "paypalInstallment": true,
    "customizableUrl": "https://www.lusini.com/fr-fr/aide-et-services/nos-services/personnalisation/",
    "b2cHomePage": true,
    "notificationBar": true,
    "flyoutCartDySlider": true,
    "showNewsletterIntentLayer": true
  },
  "abTests": {}
}
export default config